@use "variables";

@tailwind base;
@tailwind components;
@tailwind utilities; 

// Body font style
body {
    font-family: "Playfair Display", serif;
    background-color: variables.$tea-green;
    color: variables.$oxford-blue;
}

.bg-burnt-sienna {
    background-color: variables.$burnt-sienna;
}

.burnt-sienna {
    color: variables.$burnt-sienna;
}

::-moz-selection { /* Code for Firefox */
    color: white;
    background: variables.$dartmouth-green;
}
  
::selection {
    color: white;
    background: variables.$dartmouth-green;
}