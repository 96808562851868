@use "../../variables";

.cta-button:hover {
  border: 2px solid variables.$oxford-blue;
}

.home {
  &__body-image {
    transition: all 1s;
  }
}
